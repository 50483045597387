import React from "react";
import './b1_profile_certificates.css'
import CertificateCard from '../../components/certificates-list-item'
import LayoutB1Profile from "../../components/layout-b1-profile"


export default class B1ProfileCertificates extends React.Component{
  componentDidMount() {
    // let width=document.body.clientWidth
    // console.log('width',width)
    // this.setState({width})
  }
  render() {
    const certicicates=[{},{},{}]
    const certicicateslist=certicicates.map((certicicate,index)=><div key={index} className="certificate-card" style={{marginRight:index%2===0?'8.33%':'0'}}>
      <CertificateCard />
    </div>)
    return(
      <LayoutB1Profile location={this.props.location} >
        <div>
          {certicicates&&certicicates.length>0&&certicicateslist}
          <div style={{clear:'both'}}></div>
          {(!certicicates||certicicates.length===0)&&<div className="no-certicicates">
            <img src={require('../../images/b1/nodata.svg')} alt="nodata"/>
            <div>暂无证书</div>
          </div>}
        </div>
      </LayoutB1Profile>
    )
  }
}