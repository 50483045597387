import React from "react";
import './certificates-list-item.css'

export default class CertificateCard extends React.Component{
    render() {
        return(
            <div className="certificates-list-item">
                <div className="search-result">
                    <div className="result-poster"></div>
                    <div className="race-title">簇格国际马拉松</div>
                    <div className="race-record">
                        <div>小小</div>
                        <div>02:10:02</div>
                    </div>
                    <div style={{display:'flex'}}>
                        <div className="record-info-item-left">
                            <div className="record-info-title">赛事名称</div>
                            <div> 马拉松</div>
                        </div>
                        <div className="record-info-item-center">
                            <div className="record-info-title">名次</div>
                            <div>10</div>
                        </div>
                        <div className="record-info-item-right">
                            <div>
                                <div className="record-info-title">参赛编号</div>
                                <div>29810</div>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex'}}>
                        <div className="record-info-item-left">
                            <div className="record-info-title">完赛时间</div>
                            <div> 2019-11-11</div>
                        </div>
                        <div className="record-info-item-center">
                            <div className="record-info-title">枪声成绩</div>
                            <div>02:10:20</div>
                        </div>
                        <div className="record-info-item-right"></div>
                    </div>
                </div>

                <div className="down-record" onClick={this.pushRoute}>下载证书</div>
            </div>
        )
    }
}